<template>
  <div class="card" :style="{ backgroundColor: cardColor }">

    <div class="card-content">
      <p style="font-size:20px;height:40px; margin-top: 20px; font-weight: 800;">
        <span v-if="nome && nome.length > 24">
          {{ nome.toUpperCase().substring(0, 23) }}...
        </span>
        <span v-else>
          {{ nome.toUpperCase().substring(0, 26) }}
        </span>
      </p>
      <div class="card-icon">
        <i class="fas fa-solid fa-star white-icon"></i>
        <p>{{ formatarData(nascimento) }}</p>
        <i class="fas fa-cross card-icon white-icon"></i>
        <p>{{ formatarData(falecimento) }}</p>
      </div>
      <div class="card-section">
        <div class="card-section-title">
          <p>Velório</p>
          <p>Horário: {{ formatarData(velorio) }} - {{ formatarHora(velorio) }}</p>
          <p>Local: {{ capela }} </p>
        </div>
        <div class="card-section-title">
          <p>Sepultamento</p>
          <p>Horário: {{ formatarData(sepultamento) }} - {{ formatarHora(sepultamento) }} </p>
          <p>Tipo {{ areaTipo }}, Quadra {{ areaQuadra }} e Nº {{ areaNum }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nome: String,
    capela: String,
    sepultamento: String,
    velorio: String,
    cardColor: String,
    areaTipo: String,
    areaQuadra: String,
    areaNum: String,
    falecimento: String,
    nascimento: String
  },
  methods: {
    formatarHora(valor) {
      const [, hora] = valor.split(' ');
      return hora;
    },

    formatarData(horario) {
      const [data] = horario.replace("-", "/").replace("-", "/").split(' ');
      return data;
    },


  },
};
</script>

<style>
* {
  margin: 0;
}

.card-content {
  color: white;
  padding: 5px;
}

/* Estilize o card conforme necessário */
.card {
  display: inline-block;
  width: 420px;
  height: 300px;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
}

.card-icon {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.card-icon p:first-of-type {
  margin-right: 1rem;
}

.white-icon {
  color: white;
  /* Cor branca para o ícone */
}

.card-section-title {
  margin-top: 1rem;
}

.card-section-title p:first-of-type {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 6px
}

.card-image {
  width: 100px;
  height: 100px;
  margin: 10px auto;
}

.card-content {
  color: white;
  padding: 5px;
}
</style>